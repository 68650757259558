<template>
    <div class="seller px-3" v-loading="loading" element-loading-text="数据拼命加载中，请稍等~">
        <el-row :gutter="24" class="card-item">
            <el-col v-for="item in option.data" :xs="24" :md="IsPC ? item.span : 12" :lg="{ span: '4-8' }" :sm="12"
                :key="item.title" class="py-2">
                <el-card style="min-height: 100px" class="card mt-2 d-flex justify-content-center"
                    v-if="item.isShow !== 8 && item.type !== 'meTarget'">
                    <div class="d-flex justify-content-between align-items-center flex-auto" @click="to(item)">
                        <div>
                            <el-image :src="item.url" class="img-size"></el-image>
                        </div>
                        <div class="cursor-pointer">
                            <div class="option-title pb-2">{{ item.title }}</div>
                            <div class="d-flex">
                                <div style="min-width: 120px">
                                    <span class="option-count text-primary pr-1">{{ item.count }}</span>
                                    <span class="fs-10">{{ item.unit }}</span>
                                    <div class="fs-10">{{ item.label1 }}</div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <div class="tanle">
            <el-table :default-expand-all="false" :stripe="true" :data="groupData"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="_id">
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="realname" label="姓名" width="100"> </el-table-column>
                <el-table-column prop="group_name" label="所属小组">
                    <template slot-scope="scope">
                        <span :class="{
                            'font-weight-bold': !scope.row.is_child,
                            'fs-16': !scope.row.is_child,
                        }">{{ scope.row.group_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="agentTarget_value" label="本月目标">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_child">{{
                            scope.row.agentTarget_value | formatMoneyW
                            }}</span>
                        <span v-else>{{ scope.row.target_value | formatMoneyW }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="month_received" label="本月业绩（金额）">
                    <template slot-scope="scope">{{
                        scope.row.month_received | formatMoneyW
                        }}</template>
                </el-table-column>
                <el-table-column prop="agent_annual_goal" label="本年目标">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_child">{{
                            scope.row.agent_annual_goal | formatMoneyW
                            }}</span>
                        <span v-else>{{ scope.row.group_annual_goal | formatMoneyW }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="year_received" label="本年业绩（金额）">
                    <template slot-scope="scope">{{
                        scope.row.year_received | formatMoneyW
                        }}</template>
                </el-table-column>
                <el-table-column prop="address" label="年度完成">
                    <template slot-scope="scope">
                        <el-progress type="circle" :width="50" :percentage="percentage(scope.row)"></el-progress>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="analyzeItem(scope.row)"
                            v-if="isShowitem(scope.row)">数据分析</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-row>
            <el-col :span="24">
                <div class="circle_chart" ref="circle_chart"></div>
            </el-col>
            <el-col :span="24">
                <div class="circle_chart" ref="line_chart"></div>
            </el-col>
            <el-col :span="24">
                <div class="circle_chart" ref="line_chart2"></div>
            </el-col>
            <el-col :span="24" v-if="user.role === 5">
                <div class="group_chart" ref="group_chart"></div>
            </el-col>
            <el-col :md="24" class="pt-3" v-if="user.role === 0">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                    <div class="font-weight-bold table-title mb-2" :class="{ 'w-100': !IsPC }">
                        讲师排行榜
                    </div>
                    <!-- <div v-if="IsPC" class="d-flex">
                        <div class="mr-2" v-if="dataDate.way == 2">
                            <el-button type="success" plain @click="exportTeacherData">导出数据</el-button>
                        </div>
                        <el-date-picker class="mr-2" v-model="wayrankYear" :clearable="true" type="year"
                            @change="fetchTeacherDay" placeholder="选择年">
                        </el-date-picker>
                        <el-radio-group v-model="dataDate.way" size="mini">
                            <el-radio-button :label="0">线上</el-radio-button>
                            <el-radio-button :label="1">线下</el-radio-button>
                            <el-radio-button :label="2">排课金额</el-radio-button>
                        </el-radio-group>
                    </div> -->
                </div>
                <el-card class="card-2 way_rank" v-loading="wayRankLoading">
                    <table class="w-100 mt-2 text-center" v-if="wayRank.length > 0" height="100%">
                        <thead class="way_title">
                            <tr class="th-title">
                                <th class="" v-for="item in wayRnakHeaders_arr" :key="item.key">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-center text-truncate"
                                        :class="{
                                            'fs-9': IsPC,
                                            'm-fs-10': !IsPC,
                                        }">
                                        <span> {{ item.name }}</span>

                                        <sort :value="waySort[item.key]"
                                            @update:value="(val) => setWaySort(val, item.key)"
                                            v-if="item.sort && IsPC" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="cursor-pointe r" v-for="(item, index) in wayRank" :key="item._id">
                                <td v-for="(head, hi) in wayRnakHeaders_arr" :key="hi">
                                    <span v-if="head.key === 'index'" :class="{
                                        'm-fs-10': !IsPC,
                                        'fs-9': IsPC,
                                    }">{{ index + 1 }}</span>
                                    <span v-else-if="isAmountKey(head.key)" :class="{
                                        'm-fs-10': !IsPC,
                                        'fs-9': IsPC,
                                    }">
                                        ￥{{
                                            (item[head.key] && (item[head.key] / 10000).toFixed(2)) ||
                                            0
                                        }}
                                    </span>
                                    <span v-else-if="head.key === 'course_target'" :class="{
                                        'm-fs-10': !IsPC,
                                        'fs-9': IsPC,
                                    }">
                                        {{ item.course_target }} 天
                                    </span>
                                    <span v-else-if="isDaysKey(head.key)" class="text-truncate" :class="{
                                        'm-fs-10': !IsPC,
                                        'fs-9': IsPC,
                                    }">
                                        {{
                                            (item && item[head.key] && item[head.key].toFixed(2)) ||
                                            0
                                        }}天
                                    </span>
                                    <span v-else :class="{
                                        'm-fs-10': !IsPC,
                                        'fs-9': IsPC,
                                    }">{{ item[head.key] }}</span>
                                </td>
                                <!-- <td v-if="dataDate.way == 1" class="text-danger fs-8">
                  {{ item.fate }}天
                </td>
                <td v-else class="text-danger fs-8">{{ item.hour }}小时</td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="d-flex justify-content-center align-items-center h-100">
                        暂无数据
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="数据分析" :visible.sync="dialogVisible" width="80%" :before-close="handleClose"
            :close-on-click-modal="false">
            <div class="content">
                <Data :user_id="analyze_id" :circle_data="circle_data" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Data from "./data.vue";
    import * as dayjs from "dayjs";
    export default {
        data() {
            return {
                wayRank: [],
                dataDate: {
                    dataMonth: new Date(),
                    way: 1,
                },
                wayRankLoading: false,
                wayrankYear: dayjs().toDate(),
                wayRnakHeaders: [
                    {
                        name: "排名",
                        sort: false,
                        key: "index",
                    },
                    {
                        name: "讲师姓名",
                        sort: false,
                        key: "name",
                    },
                    {
                        name: "本年定课",
                        sort: true,
                        key: "course_days",
                    },
                    {
                        name: "课量目标",
                        sort: true,
                        key: "course_target",
                    },
                    {
                        name: "销售金额/万",
                        sort: true,
                        key: "order_amount",
                    },
                    {
                        name: "实施金额/万",
                        sort: true,
                        key: "implement_amount",
                    },
                    {
                        name: "已实施天数",
                        sort: true,
                        key: "implement_days",
                    },
                    {
                        name: "待实施天数",
                        sort: true,
                        key: "un_implement_days",
                    },
                    {
                        name: "预约天数",
                        sort: true,
                        key: "reserve_days",
                    },
                    {
                        name: "已回款/万",
                        sort: true,
                        key: "received_amount",
                    },
                    {
                        name: "未回款/万",
                        sort: true,
                        key: "remain_amount",
                    },

                    {
                        name: "目标差",
                        sort: true,
                        key: "diff_target",
                    },
                ],
                waySort: {},
                loading: false,
                option: {},
                users: [],
                groupData: [],
                dialogVisible: false,
                circle_chart: "",
                analyze_id: "",
                circle_options: {
                    title: {
                        text: "本月业绩",
                        left: "center",
                    },
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        orient: "vertical",
                        right: "right",
                    },

                    series: [
                        {
                            type: "pie",
                            radius: "50%",
                            data: [
                                { value: 1048, name: "月目标" },
                                { value: 735, name: "已完成" },
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                    graphic: [
                        {
                            type: "text",
                            left: "center",
                            top: "center",
                            style: {
                                text: "暂无数据",
                            },
                        },
                    ],
                },
                line_chart: "",
                line_chart2: "",
                line_options2: {
                    title: {
                        text: "当月讲师合作情况",
                        align: "center",
                    },
                    xAxis: {
                        type: "category",
                        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    },
                    yAxis: [
                        {
                            type: 'value',
                            min: 0,
                            max: 900000,
                            interval: 100000,
                            name: '合作金额',
                        },
                        {
                            type: 'value',
                            name: '合作天数',
                            min: 0,
                            max: 10,
                            interval: 10,
                            // axisLabel: {
                            //     formatter: '{value} °C'
                            // }
                        }
                    ],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    legend: {},
                    series: [
                        {
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: "bar",
                            name: "合作金额",
                            showBackground: true,
                        },
                        {
                            name: "合作天数",
                            type: "line",
                            data: [120, 200, 150, 80, 70, 110, 130],
                            yAxisIndex: 1,
                        },
                    ],
                },
                circle_data: [],
                line_options: {
                    title: {
                        text: "月度客户合作金额",
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    legend: {},
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "value",
                        boundaryGap: [0, 0.01],
                    },
                    dataZoom: [
                        {
                            type: "slider",
                            show: true,
                            start: 0,
                            end: 10,
                            yAxisIndex: [0],
                        },
                        {
                            type: "inside", //内置滑动，随鼠标滚轮展示
                            yAxisIndex: [0],
                            start: 1, //初始化时，滑动条宽度开始标度<br>
                            end: 10, //初始化时，滑动条宽度结束标度
                        },
                    ],
                    yAxis: {
                        type: "category",
                        data: ["Brazil", "Indonesia", "USA", "India", "China", "World"],
                        //   axisLabel: {
                        //     formatter: function (value) {
                        //       // 限制显示长度，超出截断，添加省略号
                        //       return value.length > 5 ? value.substring(0, 5) + "..." : value;
                        //     },
                        //     overflow: "truncate", // 截断样式
                        //     rich: {
                        //       // 可定义样式
                        //     },
                        //   },
                    },
                    series: [
                        {
                            name: "客户",
                            type: "bar",
                            data: [18203, 23489, 29034, 104970, 131744, 630230],
                        },
                    ],
                    // graphic: [
                    //   {
                    //     type: "text",
                    //     left: "center",
                    //     top: "center",
                    //     style: {
                    //       text: "暂无数据",
                    //     },
                    //   },
                    // ],
                },
                group_chart: "",
                group_option: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    toolbox: {
                        feature: {
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['line', 'bar'] },
                            restore: { show: true },
                            saveAsImage: { show: true }
                        }
                    },
                    legend: {
                        data: ['已完成', '未完成']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: [],
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                    ]
                }
            };
        },
        components: {
            Data,
        },
        methods: {
            handleClose() {
                this.dialogVisible = false;
            },
            async fetch() {
                const ret = await this.$http.post("seller/data");
                this.option = ret.data.option;
            },
            fromat(percentage) {
                return () => {
                    return (percentage || 0) + "%";
                };
            },
            analyzeItem(item) {
                this.dialogVisible = true;
                this.analyze_id = item.user_id;
                this.circle_data = item;
                //   console.log(this.analyze_id, "analyze_id");
            },
            async fetchUsers() {
                const ret = await this.$http.get("seller/group_target_list");
                this.groupData = ret.data;

                if (this.user.role === 5) {
                    this.initGroupChart(ret.data)
                }
            },
            async fetchChartOptions() {
                const res = await this.$http.get("seller/analyze_charts");
                const series = this.circle_options.series;
                series[0].data = res.data.circle_data;
                const series2 = this.line_options.series;
                const yAxis = this.line_options.yAxis;
                series2[0].data = res.data.line_data.map((v) => v.value);
                yAxis.data = res.data.line_data.map((v) => v.name);
                this.$set(this.circle_options, "series", series);
                this.$set(this.line_options, "series", series2);
                this.$set(this.line_options, "yAxis", yAxis);

                const series3 = this.line_options2.series;

                const xAxis = this.line_options2.xAxis;
                const yAxis2 = this.line_options2.yAxis;

                xAxis.data = res.data.line_teacher_data.map((v) => v.name);

                const amount_list = res.data.line_teacher_data.map(v => {
                    return v.value
                })

                series3[0].data = amount_list;

                yAxis2[0] = {
                    type: "value",
                    min: 0,
                    max: Math.max(...amount_list),
                    name: "合作金额"
                }


                const days_list = res.data.line_teacher_data.map(v => {
                    return v.days
                })



                series3[1].data = days_list;

                yAxis2[1] = {
                    type: "value",
                    min: 0,
                    max: Math.max(...days_list),
                    name: "合作天数"
                }




                this.$set(this.line_options2, "series", series3);
                this.$set(this.line_options2, "xAxis", xAxis);
                this.$set(this.line_options2, "yAxis", yAxis2);


                this.initChart();
            },
            initChart() {
                let myChart = this.$echarts.init(this.$refs.circle_chart);
                let myChart2 = this.$echarts.init(this.$refs.line_chart);
                let myChart3 = this.$echarts.init(this.$refs.line_chart2);
                myChart.setOption(this.circle_options, true);
                myChart2.setOption(this.line_options, true);
                myChart3.setOption(this.line_options2, true);
            },
            initGroupChart(users) {

                console.log(users, 'initgroupchart')

                const user = users[0]

                let myChart = this.$echarts.init(this.$refs.group_chart);

                console.log(myChart)

                const xAxis = this.group_option.xAxis;
                const yAxis = {
                    type: 'value',
                    name: '业绩完成情况',
                    axisLabel: {
                        formatter: '{value} 万元'
                    }
                };

                xAxis[0].data = user.children.map(v => v.realname)


                const series = [
                    {
                        name: '已完成',
                        type: 'bar',
                        data: user.children.map(v => v.month_received)
                    },
                    {
                        name: '未完成',
                        type: 'bar',
                        data: user.children.map(v => v.agentTarget_value)
                    },
                ]

                this.$set(this.group_option, 'series', series)
                this.$set(this.group_option, 'xAxis', xAxis)
                this.$set(this.group_option, 'yAxis', yAxis)

                console.log(this.group_option)


                myChart.setOption(this.group_option, true);


            },
            async fetchTeacherDay() {
                this.wayRankLoading = true;
                const res = await this.$http.post(`home/teacher_day`, {
                    ...this.dataDate,
                    sort: this.waySort,
                    wayrankYear: this.wayrankYear,
                });
                this.wayRank = res.data;
                this.wayRankLoading = false;
            },
        },
        computed: {
            isShowitem() {
                return (item) => {
                    if (this.user.role == 0) {
                        return item.is_group_team
                    } else if (this.user.role == 1) {
                        return true;
                    } else if (this.user.role == 5) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            percentage() {
                return (item) => {
                    let total = item.is_child ? item.agent_annual_goal : item.group_annual_goal;
                    // console.log(1);

                    if (!item.year_received) {
                        return 0;
                    }
                    // console.log(2);
                    if (total < item.year_received) {
                        return 100;
                    }

                    // console.log({
                    //   progress,
                    //   year_received: item.year_received,
                    //   is_child: item.is_child,
                    //   total,
                    //   item,
                    // });
                    // console.log(3);

                    if (!total) {
                        return 0;
                    }
                    const progress = Math.floor((item.year_received / total) * 100);

                    return progress;
                };
            },
            wayRnakHeaders_arr() {
                if (this.IsPC) {
                    return this.wayRnakHeaders;
                } else {
                    let showKeys = [
                        "index",
                        "name",
                        "course_days",
                        "order_amount",
                        "implement_amount",
                        "received_amount",
                        "remain_amount",
                        "diff_target",
                    ];
                    return this.wayRnakHeaders.filter((v) => showKeys.includes(v.key));
                }
            },
            isAmountKey() {
                return (key) => {
                    let amountKeys = [
                        "order_amount",
                        "implement_amount",
                        "received_amount",
                        "remain_amount",
                    ];
                    return amountKeys.includes(key);
                };
            },
            isDaysKey() {
                return (key) => {
                    let daysKey = [
                        "course_days",
                        "implement_days",
                        "un_implement_days",
                        "reserve_days",
                        // "course_target",
                        "diff_target",
                    ];
                    return daysKey.includes(key);
                };
            },
            showWayRankHeader() {
                return (key) => {
                    if (this.IsPC) {
                        return true;
                    } else {
                        console.log(this.IsPC);

                        let showKeys = [
                            "index",
                            "name",
                            "course_days",
                            "order_amount",
                            "implement_amount",
                            "received_amount",
                            "remain_amount",
                            "diff_target",
                        ];

                        return showKeys.includes(key);
                    }
                };
            },


        },
        mounted() {
            this.fetch();
            this.fetchUsers();
            this.initChart();
            this.fetchChartOptions();
            if (this.user.role == 0) {
                this.fetchTeacherDay()
            }
        },
    };
</script>

<style>
    .seller {
        height: 100%;
    }

    .circle_chart {
        min-height: 400px;
        margin: 30px;
    }

    .group_chart {
        margin: 30px;
        min-height: 400px;

    }
</style>
